import http from './request.js'

// 用户登录
export const userLogin = (params = {}) => {
  // return http().post('/user/api/user/login/password', params)
  return http().post('/user/api/user/info/login/pwd', params)
}

// 工号登录
export function userNoLogin(params = {}) {
  return http().post('/user/api/user/info/emp/no/pwd', params)
}

// 获取用户信息
export const getUserInfo = (params = {}, token = '') => {
  return http(token).get('/user/auth/enterprise/user/info/current/view', params)
}

// 获取手机验证码
export const getMobileCode = (params = {}) => {
  return http().post('/user/api/user/info/send/verification/code', params)
}

// 校验验证码
export const vaildateCode = (params = {}) => {
  return http().post('/user/api/user/info/check/verification/code', params)
}

// 企业注册
export const entRegister = (params = {}) => {
  return http().post('/user/api/enterprise/info/register', params)
}

// 企业讲师等级列表
export const lecturerRankList = (data = {}) => {
  return http().post('/user/auth/lecturer/rank/list', data)
}

// 企业讲师信息分页
export const lecturerList = (params = {}) => {
  return http().post('/user/auth/lecturer/info/page', params)
}

// 讲师信息分页(线下课授课老师使用)
export const lecturerQueryList = (params = {}) => {
  return http().post('/user/auth/lecturer/info/query/page', params)
}

// 讲师详情信息
export const lecturerDetail = (params = {}) => {
  return http().get('/user/auth/lecturer/info/detail/view?lecturerId=' + params.lecturerId)
}

// 讲师基础信息
export const lecturerBase = (params = {}) => {
  return http().get('/user/auth/lecturer/info/base/view?lecturerId=' + params.lecturerId)
}

export function getUserEnterpriseList() {
  return http().get('/user/auth/user/info/enterprise/list')
}

export function chooseEnterprise(data) {
  return http().post('/user/auth/user/info/choose/enterprise', data)
}

export function logout() {
  return http().put('/user/auth/user/info/logout')
}

// 公告用户记录分页
export const userAnnouncementList = (params = {}) => {
  return http().post('/user/auth/announcement/user/record/page', params)
}

// 公告用户阅读统计
export const announcementReadCount = (params = {}) => {
  return http().post('/user/auth/announcement/user/record/read/count', params)
}

// 公告用户记录批量删除
export const announcementDel = (params) => {
  // return http().delete(`/user/auth/announcement/user/record/batch/delete`, params)
  return http().delete('/user/auth/announcement/user/record/batch/delete', { data: params })
}

// 公告用户记录批量设置已读
export const announcementRead = (params) => {
  return http().put(`/user/auth/announcement/user/record/batch/read?`, params)
}

// 公告用户记录查看
export const announcementView = (id) => {
  return http().get('/user/auth/announcement/user/record/view?id=' + id)
}

// 公告点赞
export function announcementAdmire(id) {
  return http().put('/user/auth/announcement/user/record/update/admire?id=' + id)
}

// 校验手机
export function checkPhoneExist(phone) {
  return http().get(`/user/api/user/info/check/phone/exist?phone=${phone}`)
}

// 校验验证码
export function checkVerificationCode(data) {
  return http().post(`/user/api/user/info/check/verification/code`, data)
}

// 发送验证码
export function sendVerificationCode(data) {
  return http().post('/user/api/user/info/send/verification/code', data)
}

// 注册
export function enterpriseInfoRegister(data) {
  return http().post('/user/api/enterprise/info/register', data)
}

// 城市
export function regionList(data) {
  return http().post('/system/api/region/list', data)
}

// 验证码登录
export function LoginCode(data) {
  return http().post('/user/api/user/info/login/verification/code', data)
}

/**
 * 考试任务 当前用户统计
 * @param data
 * @returns {*}
 */
export function getExamUserCurrentCount(data) {
  return http().post('/exam/auth/user/exam/task/record/current/user/count', data)
}

/**
 * 公告评论分页
 * @param param
 * @param page
 * @param size
 * @returns {*}
 */
export function announcementCommentPage(param = {}, page = 1, size = 10) {
  return http().post('/user/auth/announcement/comment/page', { pageCurrent: 1, pageSize: 10, ...param })
}

/**
 * 公告评论添加
 * @param data
 * @returns {*}
 */
export function addComment(data) {
  return http().post('/user/auth/announcement/comment/save', data)
}

/**
 * 公告评论回复
 * @param data
 * @returns {*}
 */
export function replyComment(data) {
  return http().post('/user/auth/announcement/comment/reply/save', data)
}

/**
 * 公告评论回复删除
 * @param id
 * @returns {*}
 */
export function deleteReplyComment(id) {
  return http().delete('/user/auth/announcement/comment/reply/delete?id=' + id)
}

/**
 * 公告评论删除
 * @param id
 * @returns {*}
 */
export function deleteComment(id) {
  return http().delete('/user/auth/announcement/comment/delete?id=' + id)
}
// 用户基本信息修改密码
export function updatePassword(data) {
  return http().put('/user/auth/user/info/update/password', data)
}

// 忘记密码
export function forgetPassword(data) {
  return http().put('/user/api/user/info/reset/password', data)
}

// 用户消息通知-列表
export function userNoticePage(data) {
  return http().post('/user/auth/user/msg/notice/page', data)
}
// 用户消息通知-数量统计
export function userNoticeCount(data) {
  return http().post('/user/auth/user/msg/notice/count', data)
}
// 用户消息通知-查看
export function userNoticeView(id) {
  return http().get('/user/auth/user/msg/notice/view?id=' + id)
}
// 用户消息通知-批量删除
export function userNoticeBatchDelete(data) {
  return http().post('/user/auth/user/msg/notice/batch/delete', data)
}
// 用户消息通知-批量标记已读
export function userNoticeRead(data) {
  return http().post('/user/auth/user/msg/notice/batch/read', data)
}

// 用户调研任务 - 分页
export function investigateTaskPage(data) {
  return http().post('/exam/auth/investigate/task/page', data)
}

// 用户调研任务 - 数量统计
export function investigateTaskCount(data) {
  return http().post('/exam/auth/investigate/task/count', data)
}

