import { render, staticRenderFns } from "./childResource.vue?vue&type=template&id=bfe9b52e&scoped=true"
import script from "./childResource.vue?vue&type=script&lang=js"
export * from "./childResource.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe9b52e",
  null
  
)

export default component.exports